import { lazy } from "react"
import { Navigate } from "react-router-dom"
import AuthenticatedLayout from "./components/layout/AuthenticatedLayout"
import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard/Dashboard"

const Policies = lazy(() => import("./pages/Policies/Policies"))
const SupportTickets = lazy(() => import("./pages/SupportTickets/SupportTickets"))
const Integrations = lazy(() => import("./pages/Integrations/Integrations"))
const IntegrationInitiate = lazy(() => import("./pages/Integrations/IntegrationInitiate"))
const IntegrationConfirm = lazy(() => import("./pages/Integrations/IntegrationConfirm"))
const RecommendationApproval = lazy(() => import("./pages/Policies/RecommendationApproval"))
const PartnerCompanySettings = lazy(() => import("./pages/Dashboard/PartnerCompanySettings"))
const Settings = lazy(() => import("./pages/Settings/Settings"))
const IntegrationManage = lazy(() => import("./pages/Integrations/IntegrationManage"))

const NotFound = lazy(() => import("./pages/NotFound"))

export const routes = [
    {
        path: "/login",
        element: <Login />,
    },
    {
        element: <AuthenticatedLayout />,
        children: [
            {
                path: "/",
                element: <Navigate to="/dashboard" replace />,
            },
            {
                path: "/dashboard",
                element: <Dashboard />,
            },
            {
                path: "/policies",
                element: <Policies />,
            },
            {
                path: "/support-tickets",
                element: <SupportTickets />,
            },
            {
                path: "/integrations",
                element: <Integrations />,
            },
            {
                path: "/integrations/:appName/initiate",
                element: <IntegrationInitiate />,
            },
            {
                path: "/integrations/:appName/confirm",
                element: <IntegrationConfirm />,
            },
            {
                path: "/integrations/:appName/manage",
                element: <IntegrationManage />,
            },
            {
                path: "/recommendations/approval/:recoId",
                element: <RecommendationApproval />,
            },
            {
                path: "/partner-company-settings/:partnerConnection",
                element: <PartnerCompanySettings />,
            },
            {
                path: "/settings",
                element: <Settings />,
            },
        ],
    },
    {
        path: "*",
        element: <NotFound />,
    },
]
